<template>
  <el-dropdown-item @click.stop="handleImport">
    <svgicon
      :src="require('@/assets/icons/th-icon-upload.svg')"
      :style="{ height: '20px', width: '20px' }"
      class="mr-2 fill-current"
    />
    <span>{{ $t('common.interactions.buttons.import') }}</span>
  </el-dropdown-item>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useCustomersStore } from '@/store/customers'
import { useI18n } from 'vue-i18n'
import th from '@tillhub/javascript-sdk'
import FlatfileImporter from '@flatfile/adapter'
import get from 'just-safe-get'
import { parse } from 'date-fns'
import { isEmptyString } from '@/utils/strings'
import { i18nOverrides, splitArrayIntoChunks } from '@/utils/importer'
import { availableCountries } from '@/constants'
import { removePercentSign, encodePercent } from '@/utils/numbers'
import { defaultDiscount } from '../helpers'

const FLATFILE_LICENSE_KEY = process.env.VUE_APP_FLATFILE_LICENSE_KEY
const CHUNK_NUMBER = 200

export default {
  name: 'CustomerImporter',
  setup() {
    //store
    const store = useStore()
    const clientAccountConfiguration = computed(
      () => store.getters['Config/getClientAccountConfiguration']
    )

    const customersStore = useCustomersStore()

    const userId = computed(() => store.state.Auth.user || '-')
    const orgName = computed(() => store.state.Auth.orgName || '-')

    //i18n
    const { t, tm } = useI18n()

    const fields = computed(() => [
      {
        key: 'firstname',
        label: t('common.forms.labels.firstname')
      },
      {
        key: 'lastname',
        label: t('common.forms.labels.lastname'),
        validators: [{ validate: 'required' }]
      },
      {
        key: 'customer_number',
        label: t('pages.customers.form.placeholder.customer_number'),
        validators: customerNumberValidators.value
      },
      {
        key: 'discount',
        label: t('common.forms.labels.discount'),
        validators: [
          {
            // Valid discount value
            validate: 'regex_matches',
            // Matches 0.01 to 99.99 with or without percent sign
            regex: '^(?!0+(?:\\.0+)?$)\\d?\\d(?:\\.\\d\\d?)?([\\s%]+)?$',
            error: t('common.forms.warning.invalid_input.title')
          }
        ]
      },
      {
        key: 'company_name',
        label: t('common.forms.labels.company_name'),
        validators: [
          {
            // Max length 64
            validate: 'regex_matches',
            regex: `^.{0,64}$`,
            error: t('common.forms.rules.min_max_length', {
              min: 0,
              max: 64
            })
          }
        ]
      },
      {
        key: 'email',
        label: t('common.forms.labels.email'),
        validators: [
          {
            // The regex for email validation is in accordance to `RFC 2822 standard`
            // Source: https://www.w3resource.com/javascript/form/email-validation.php
            validate: 'regex_matches',
            regex:
              '^((?:[aA-zZ0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[aA-zZ0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[aA-zZ0-9](?:[aA-zZ0-9-]*[aA-zZ0-9])?\\.)+[aA-zZ0-9](?:[aA-zZ0-9-]*[aA-zZ0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[aA-zZ0-9-]*[aA-zZ0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\]))$',
            error: t('pages.customers.importer.validation.email.error')
          }
        ]
      },
      {
        key: 'phonenumber',
        label: t('common.forms.labels.phonenumber')
      },
      {
        key: 'date_of_birth',
        label: t('common.forms.labels.date_of_birth'),
        validators: [
          {
            validate: 'regex_matches',
            regex: `\\s*((?:19|20)\\d{2})\\-(1[012]|0[1-9])\\-(3[01]|[12][0-9]|0[1-9])\\s*`,
            error: t('pages.customers.importer.validation.date_of_birth.error')
          }
        ]
      },
      {
        key: 'street',
        label: t('common.forms.labels.street')
      },
      {
        key: 'street_number',
        label: t('common.forms.labels.street_number')
      },
      {
        key: 'locality',
        label: t('common.forms.labels.city')
      },
      {
        key: 'postal_code',
        label: t('common.forms.labels.postal_code'),
        validators: [
          {
            validate: 'regex_matches',
            regex: `^.{0,9}$`,
            error: t('pages.customers.importer.validation.postal_code.error')
          }
        ]
      },
      {
        key: 'region',
        label: t('common.forms.labels.region')
      },
      {
        type: 'select',
        options: availableCountries.map((v) => {
          return { value: v, label: v }
        }),
        key: 'country',
        label: t('common.forms.labels.country')
      },
      {
        key: 'comment',
        label: t('pages.customers.form.placeholder.comment'),
        validators: [
          {
            // Max length 1024
            validate: 'regex_matches',
            regex: `^.{0,1024}$`,
            error: t('common.forms.rules.min_max_length', {
              min: 0,
              max: 1024
            })
          }
        ]
      }
    ])

    //computed
    const shouldAutoGenerateCustomerNumber = computed(
      () =>
        !!get(
          clientAccountConfiguration.value,
          'customers.generate_customer_number'
        )
    )

    const customerNumberValidators = computed(() =>
      shouldAutoGenerateCustomerNumber.value ? [{ validate: 'required' }] : []
    )

    const importer = computed(
      () =>
        new FlatfileImporter(FLATFILE_LICENSE_KEY, {
          fields: fields.value,
          type: t('pages.customers.title'),
          allowInvalidSubmit: false,
          managed: true,
          disableManualInput: true,
          devMode: process.env.NODE_ENV !== 'production',
          styleOverrides: {
            primaryButtonColor: '#279ff6',
            invertedButtonColor: '#7bbaf3'
          },
          i18nOverrides: {
            de: {
              otherLocales: ['de-DE'],
              overrides: i18nOverrides({
                header: tm('pages.customers.importer.flatfile.header'),
                header2: tm('pages.customers.importer.flatfile.header2')
              })
            },
            en: {
              otherLocales: ['en-US', 'en-GB'],
              overrides: i18nOverrides({
                header: tm('pages.customers.importer.flatfile.header'),
                header2: tm('pages.customers.importer.flatfile.header2')
              })
            }
          }
        })
    )

    return {
      userId,
      orgName,
      importer,
      clientAccountConfiguration,
      customersStore,
      shouldAutoGenerateCustomerNumber
    }
  },
  async mounted() {
    this.importer.setCustomer({ userId: this.userId, name: this.orgName })
    this.importer.registerRecordHook(this.validateRecords)
    this.importer.registerFieldHook('discount', this.parseDiscount)
  },
  methods: {
    async handleImport() {
      try {
        const results = await this.importer.requestDataFromUser()
        this.importer.displayLoader()
        await this.createCustomers(results.data)
        this.importer.displaySuccess(
          this.$t('pages.customers.importer.messages.success')
        )
        this.$emit('refresh')
      } catch (err) {
        // Flatfile throws undefined error when user closes the importer by clicking X button.
        if (err) {
          this.$logException(err, { trackError: false })
          this.importer.displayError(
            this.$t('pages.customers.importer.messages.error')
          )
        }
      }
    },
    async createCustomers(customers) {
      const normalizedCustomers = this.normalizeCustomers(customers)
      await this.createCustomersByChunks(normalizedCustomers)
      if (this.customersStore.customersCount === 0) {
        this.customersStore.checkCustomersCount()
      }
    },
    // Create customers by chunks and returns an array of the created customers
    async createCustomersByChunks(customers) {
      let createdCustomers = []
      const arrayOfCustomers = splitArrayIntoChunks(customers, CHUNK_NUMBER)
      const query = {
        query: {
          generate_customer_number: this.shouldAutoGenerateCustomerNumber
        }
      }
      const thCustomers = th.customers()
      for (const customers of arrayOfCustomers) {
        try {
          const response = await thCustomers.bulkCreate(customers, query)
          createdCustomers = createdCustomers.concat(
            response?.data?.updated_customers || []
          )
        } catch (err) {
          this.$logException(err, { trackError: false })
        }
      }

      return createdCustomers
    },
    cleanEmptyStringFields(customer) {
      let cleanCustomer = { ...customer }
      // replace empty strings with null
      Object.keys(customer).forEach((key) => {
        if (isEmptyString(customer[key])) {
          cleanCustomer[key] = null
        }
      })
      return cleanCustomer
    },
    normalizeCustomers(customers) {
      return customers.map((customer) => {
        let normalizedCustomer = null
        const cleanCustomer = this.cleanEmptyStringFields(customer)
        const {
          phonenumber,
          company_name,
          date_of_birth,
          street = null,
          street_number = null,
          locality = null,
          country = null,
          postal_code = null,
          region = null,
          discount,
          ...restCustomer
        } = cleanCustomer

        normalizedCustomer = { ...restCustomer }

        if (company_name) {
          normalizedCustomer.company = { name: company_name }
        }

        if (phonenumber) {
          normalizedCustomer.phonenumbers = { main: phonenumber }
        }

        if (
          street ||
          street_number ||
          locality ||
          country ||
          postal_code ||
          region
        ) {
          normalizedCustomer.addresses = [
            {
              type: 'billing',
              street,
              street_number,
              country,
              postal_code,
              region,
              locality,
              lines: null
            }
          ]
        }

        // Was decided this time format for the DOB 'yyyy-MM-dd'
        // Could be improved by a more dynamic recognition of the date format
        if (date_of_birth) {
          normalizedCustomer.date_of_birth = parse(
            date_of_birth,
            'yyyy-MM-dd',
            new Date()
          )
        }

        if (discount) {
          const parsedDiscount = Number(removePercentSign(discount))
          normalizedCustomer.discounts = [
            { ...defaultDiscount, amount: parsedDiscount }
          ]
        }

        return normalizedCustomer
      })
    },
    validateRecords(record, index, mode) {
      let out = {}
      // NOTE: for performance it's best to use registerRecordHook only for user changes and not for initial parse, for that use registerFieldHook
      if (mode !== 'change') return out

      if (
        record.discount &&
        !isNaN(Number(removePercentSign(record.discount)))
      ) {
        out.discount = {
          // In order to make sure we don't have double percent signs, we first remove if there's any
          value: removePercentSign(record.discount) + ' %'
        }
      }

      return out
    },
    parseDiscount(discounts) {
      // Customers template stores discount value as a decimal, so we need to transform it to a human readable percentage.
      return discounts.map(([discount, index]) => [
        { value: encodePercent(discount) + ' %' },
        index
      ])
    }
  }
}
</script>
