<template>
  <th-page-wrapper>
    <customers-list-empty
      v-if="!customersStore.customersExists"
      :template-href="templateHref"
      @refresh="refresh"
    />
    <th-datatable
      v-show="customersStore.customersExists"
      ref="table"
      v-loading="loading"
      class="hotjar-ignore"
      :headers="headers"
      do-route
      do-route-filters
      :show-operations="false"
      :operations="operations"
      :resource-limit="100"
      resource="customersV1"
      route-base="/customers"
      :resource-query="{ query: resourceQuery }"
      :meta-options="resourceQuery"
      :transform-fetched-data="transformFetchedData"
      transform-fetched-meta-allowed
      :locale="locale"
      multiple-select
      headers-filterable
      :headers-config="headersConfig"
      :loading-text="loadingText"
      show-search-filter
      :search-filters="filtersList"
      :buttons="computedButtons"
      :retry-options="retryOptions()"
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      :headers-default-hide="headersDefaultHide"
      @headers-config="handleHeadersConfig"
      @selection-change="handleSelectionChange"
      @loading-error="handleLoadingError"
    >
      <template #actions>
        <div class="actions">
          <actions
            v-permissions="{
              scopes: ['customers:delete']
            }"
            :selected-items="selectedItems"
            @delete-requested="handleDelete"
          />
        </div>
      </template>
      <template #dropdown-items-start>
        <div>
          <template-file-button :href="templateHref" />
          <customer-importer
            v-if="$checkPermissions({ scopes: ['customers:create'] })"
            @refresh="refresh"
          />
        </div>
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import { useCustomersStore } from '@/store/customers'
import th from '@tillhub/javascript-sdk'
import typeOf from 'just-typeof'
import get from 'just-safe-get'
import Actions from '@components/actions/delete'
import fullName from '@/utils/full-name.js'
import CustomersListEmpty from './empty'
import CustomerImporter from './components/customer-importer'
import TemplateFileButton from '@components/importer/template-file-button'
import { waitForData } from '@/utils/general'
import { useMessagesStore } from '@/store/messages'
import { useExportsStore } from '@/store/exports'

const headersConfigPath = 'settings.headerFilters.customers'

export default {
  name: 'CustomersAll',
  metaInfo() {
    return {
      title: this.$t('common.resource.customer.plural')
    }
  },
  components: {
    Actions,
    CustomersListEmpty,
    CustomerImporter,
    TemplateFileButton
  },
  setup() {
    const customersStore = useCustomersStore()

    return { customersStore }
  },
  data() {
    return {
      loading: false,
      templateHref:
        'https://storage.googleapis.com/tillhub-dashboard/templates/vorlage_kundenliste.xlsx',
      loadingExport: false,
      selectedItems: [],
      resources: {},
      permissions: {
        create: {
          scopes: ['customers:create']
        },
        edit: {
          scopes: ['customers:update']
        },
        delete: {
          scopes: ['customers:delete']
        }
      },
      width: 500,
      headers: [
        {
          field: 'lastname',
          label: this.$t('pages.customers.all.headers.lastname'),
          fallback: '-',
          minWidth: 130,
          truncate: true
        },
        {
          field: 'firstname',
          label: this.$t('pages.customers.all.headers.firstname'),
          fallback: '-',
          minWidth: 130,
          truncate: true
        },
        {
          field: 'customer_number',
          label: this.$t('pages.customers.all.headers.customer_number'),
          fallback: '-',
          minWidth: 140,
          truncate: true
        },
        {
          field: '_company_label',
          label: this.$t('pages.customers.all.headers.company'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'email',
          label: this.$t('pages.customers.all.headers.email'),
          fallback: '-',
          minWidth: 200,
          truncate: true
        },
        {
          field: '_phonenumbers_label',
          label: this.$t('pages.customers.all.headers.phonenumbers'),
          fallback: '-',
          minWidth: 140,
          truncate: true
        },
        {
          field: '_newsletter_label',
          label: this.$t('pages.customers.form.labels.newsletter'),
          fallback: '-',
          minWidth: 130,
          truncate: true
        },
        {
          field: '_is_b2b_label',
          label: this.$t('pages.customers.form.labels.b2b'),
          fallback: '-',
          minWidth: 130,
          truncate: true
        },
        {
          field: 'source',
          label: this.$t('pages.customers.all.headers.source'),
          minWidth: 200,
          truncate: true,
          formatter: ({ source }) => {
            let key

            if (source === 'WEB_RESERVATION') {
              key = 'pages.customers.source.appointment_booking'
            } else if (source === 'APPOINTMENT_CALENDAR') {
              key = 'pages.customers.source.employee_calendar'
            } else if (source === 'DASHBOARD') {
              key = 'pages.customers.source.dashboard'
            } else if (source === 'POS') {
              key = 'pages.customers.source.pos'
            }

            if (!key) {
              return '-'
            }

            return this.$t(key)
          }
        }
        // {
        //   field: '_first_seen_branch_label',
        //   label: this.$t('pages.customers.all.headers.first_seen'),
        //   fallback: '-',
        //   minWidth: 200,
        //   truncate: true
        // }
      ],
      headersDefaultHide: ['_newsletter_label', '_is_b2b_label'],
      loadingText: ''
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      currentLocation: 'Config/getCurrentLocation',
      localConfiguration: 'Config/getLocalConfiguration'
    }),
    resourceQuery() {
      return {
        deleted: false,
        locations: this.currentLocation || undefined
      }
    },
    buttons() {
      return [
        {
          type: 'create',
          scopes: ['customers:create']
        },
        {
          type: 'custom_export',
          scopes: ['customers:export'],
          clickHandler: ({ handleDownload, resourceOptions }) => {
            this.handleExport({ handleDownload, resourceOptions })
          }
        }
      ]
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    headersConfig() {
      return get(this.localConfiguration, headersConfigPath, {})
    },
    operations() {
      const permissions =
        typeOf(this.permissions) === 'object' ? this.permissions : {}
      return Object.entries(permissions).reduce(
        (ops, [action = '', { scopes = [] }]) => {
          if (!action || !scopes || !scopes.length) return ops

          const permissionVal = this.$checkPermissions({ scopes })

          if (typeOf(permissionVal === 'boolean')) {
            ops[action] = permissionVal
          }

          return ops
        },
        {}
      )
    },
    filtersList() {
      return [
        {
          name: 'lastname',
          type: 'input',
          label: this.$t('pages.customers.all.filters.lastname.label')
        },
        {
          name: 'firstname',
          type: 'input',
          label: this.$t('pages.customers.all.filters.firstname.label')
        },
        {
          name: 'email',
          type: 'input',
          label: this.$t('pages.customers.all.filters.email.label')
        },
        {
          name: 'phonenumbers',
          type: 'input',
          label: this.$t('pages.customers.all.filters.phonenumbers.label')
        },
        {
          name: 'company',
          type: 'input',
          label: this.$t('pages.customers.all.filters.company.label')
        },
        {
          name: 'postal_code',
          type: 'input',
          label: this.$t('pages.customers.all.filters.zip.label')
        },
        {
          name: 'customer_number',
          type: 'input',
          label: this.$t('pages.customers.all.filters.customer_number.label')
        },
        {
          name: 'address',
          type: 'input',
          label: this.$t('pages.customers.all.filters.address.label')
        },
        {
          name: 'price_books',
          type: 'multiselect',
          label: this.$t('common.resource.pricebook.singular'),
          options: this.resources?.pricebooks?.map((pricebook) => {
            return {
              label: pricebook.name,
              value: pricebook.id
            }
          })
        },
        {
          name: 'created_at',
          type: 'daterange',
          label: this.$t('pages.customers.all.filters.created_at.label'),
          preventDefaultDates: true,
          formatValue: (value) => this.$date.formatDateRange(value),
          modifyFilter: (filterObject) => ({
            created_at_start: filterObject.start,
            created_at_end: filterObject.end
          })
        }
      ]
    }
  },
  async mounted() {
    await this.fetchResources()

    this.$emitter.on('refresh-requested', () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    async fetchResources() {
      try {
        this.loading = true
        const { branchesV1 = [], pricebooks = [] } = await this.$resourceFetch(
          'branchesV1',
          {
            resource: 'pricebooks',
            handler: () =>
              th
                .products()
                .pricebooks()
                .getAll({
                  query: { deleted: false, type: 'customer' }
                })
          }
        )
        this.resources = { branches: branchesV1, pricebooks }
      } catch (err) {
        this.$logException(err, {
          trackError: false
        })
      } finally {
        this.loading = false
      }
    },
    handleSelectionChange(val) {
      this.selectedItems = val
    },
    refresh() {
      this.$refs.table.refresh()
      this.customersStore.checkCustomersCount()
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: headersConfigPath,
        value: config || {}
      })
    },
    async handleDelete(payload) {
      const customerName = fullName({
        firstname: payload[0].firstname,
        middlename: payload[0].middlename,
        lastname: payload[0].lastname
      })
      const confirm = await this.$askToDeleteMany(
        payload,
        this.$t('common.delete_confirm.object_names.customers'),
        'firstname',
        customerName
      )
      if (confirm) this.deleteCustomer(payload)
    },
    async deleteCustomer(payload) {
      if (this.selectedItems.length > 1) {
        this.handleBulkDelete(this.selectedItems)
        return
      }
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.customer.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.customer.singular')
      })

      try {
        const inst = th.customersV1()
        await inst.delete(payload[0].id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.refresh()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    handleBulkDelete(items) {
      const inst = th.customersV1()

      const operations = items.map((item) => {
        return () => inst.delete(item.id)
      })

      const label = this.$t('common.success.action.delete.multiple', {
        resources: this.$t('common.resource.customer.plural')
      })

      const currentRoute = this.$route.fullPath

      const fulfillment = () => {
        //check customer count
        this.customersStore.checkCustomersCount()

        if (this.$route.fullPath === currentRoute) {
          this.refresh()
        }
      }

      useMessagesStore().startLocalOperation({
        operations,
        label,
        fulfillment
      })
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.customers.title')
        })
      })
    },
    retryOptions() {
      const loadingMsg = this.$t('common.loading.long')
      return {
        retries: 4,
        onFailedAttempt: (err) => {
          if (err.attemptNumber === 2) {
            this.loadingText = loadingMsg
          }
        }
      }
    },
    async transformFetchedData(data) {
      await waitForData(() => this.resources.branches)

      return data.map((item) => {
        const _item = item.doc || item
        const { branches } = this.resources

        if (item.branch_id) {
          const branch = branches.find((branch) => branch.id === item.branch_id)

          item._first_seen_branch_label = branch?.name
        }

        if (_item.company && _item.company.name) {
          _item._company_label = _item.company.name
        }

        if (_item.phonenumbers) {
          _item._phonenumbers_label =
            _item.phonenumbers.mobile || _item.phonenumbers.main || '-'
        }

        _item._newsletter_label = get(item, 'contacts.newsletter.enabled')
          ? this.$t('common.interactions.buttons.yes')
          : this.$t('common.interactions.buttons.no')

        if (typeof _item.is_b2b === 'boolean') {
          _item._is_b2b_label =
            _item.is_b2b === true
              ? this.$t('common.interactions.buttons.yes')
              : this.$t('common.interactions.buttons.no')
        }

        return _item
      })
    },
    async handleExport({ resourceOptions }) {
      const query = {
        ...resourceOptions,
        query: {
          ...resourceOptions.query,
          format: 'csv',
          filename_prefix: this.$t('pages.customers.title'),
          timezone: this.timeZone || 'Europe/Berlin'
        }
      }
      try {
        const { customersV1 = [] } = await this.$resourceFetch({
          resource: 'customersV1',
          handler: () => th.customersV1().getAll(query)
        })

        const exportId = customersV1[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.customers.title',
            date: new Date(),
            action: {
              entity: 'customersV1',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.customers.title')
          })
        })
      }
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: center;
  align-items: center;
}

.actions > * {
  margin-right: 10px;
}

.popover {
  margin-right: 10px;
}

.tools > div {
  display: flex;
  align-items: flex-end;
}
</style>
