<template>
  <div>
    <el-button
      plain
      icon="Delete"
      :disabled="!isAnySelected"
      class="el-button--text-icon"
      @click="handleDelete"
    />
  </div>
</template>

<script>
import thIconDelete from '@/assets/icons/th-icon-delete.svg'

export default {
  props: {
    selectedItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return { thIconDelete }
  },
  computed: {
    isAnySelected() {
      return this.selectedItems.length > 0
    }
  },
  methods: {
    handleDelete() {
      this.$emit('delete-requested', this.selectedItems)
    }
  }
}
</script>
