<template>
  <div
    class="w-full h-full flex flex-col bg-th-navigation"
    data-testid="customers-empty"
  >
    <div class="flex justify-between items-center py-6 pr-6 pl-8">
      <div
        class="h-full flex flex-col flex-end font-bold leading-7 text-lg"
        :class="{ italic: !isWhiteLabel }"
      >
        <div>{{ $t('pages.customers.empty.text.title') }}</div>
        <div>{{ $t('pages.customers.empty.text.sub') }}</div>
      </div>
      <el-dropdown
        split-button
        type="primary"
        icon="Plus"
        @click="computedButtons[0].clickHandler"
      >
        <span>{{ computedButtons[0].label }}</span>
        <template #dropdown>
          <el-dropdown-menu>
            <template-file-button :href="templateHref" />
            <customer-importer
              v-if="$checkPermissions(['customers:create'])"
              @refresh="$emit('refresh')"
            />
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="h-full flex min-h-0 min-w-0 items-center justify-center">
      <img
        :src="customersListEmpty"
        alt="customers-list-empty"
        style="max-width: 80%; max-height: 71vh"
      />
    </div>
    <div class="th-shadow-top h-20" />
  </div>
</template>

<script>
import customersListEmpty from '@/assets/illustrations/customers-list-empty.svg'
import CustomerImporter from './components/customer-importer'
import TemplateFileButton from '@components/importer/template-file-button'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: { CustomerImporter, TemplateFileButton },
  props: {
    templateHref: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      customersListEmpty,
      buttons: [
        {
          scopes: ['customers:create'],
          svgicon: 'th-icon-plus',
          label: this.$t('common.components.th_datatable.addNew'),
          clickHandler: this.handleNew
        }
      ]
    }
  },
  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  methods: {
    handleNew() {
      this.$router.push({ name: 'customer-new' })
    }
  }
}
</script>

<style scoped>
.th-shadow-top {
  box-shadow: 0px -4px 4px rgba(167, 171, 177, 0.15);
}
</style>
